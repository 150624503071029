import  * as schema from 'yup';
import { BillingFormModel, BillingModel, StripeFormModel } from '../models/integrationSettings';

export const billingSchemaConst = {
	maxLengh: 200,
};

export const formSchema = schema.object<Partial<Record<keyof BillingModel, schema.AnySchema>>>({
	stripe: schema.object<Partial<Record<keyof StripeFormModel, schema.AnySchema>>>({
		// url: schema.string().matches(/(^(?:https?|wss?):\/\/((?:[\w-]+)(?:\.[\w-]+)*)(?:[\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?$)|^$/, 'general.error_url_pattern').max(billingSchemaConst.maxLengh, 'general.error_max_length').nullable(),
		// publicKey: schema.string().matches(/((^pk_test_)|(^pk_live_))|^$/, 'settings.billing.stripe.invalid_public_key').nullable(),
		// secretKey: schema.string().required('general.mandatory_field').nullable()
	}),
	billing: schema.object<Partial<Record<keyof BillingFormModel, schema.AnySchema>>>().shape({
		immediateBillingAllowed: schema.boolean().default(false),
		periodicBillingAllowed: schema.boolean().default(false)
	}).test('at-least-one', 'At least one billing option must be allowed', value => {
		return value.immediateBillingAllowed || value.periodicBillingAllowed;
	})
});